<template>
  <div>
    <v-card flat color="accent rounded-0">
      <v-row>
        <v-col md="2">
          <!-- <box-regular username="Controllers"></box-regular> -->
        </v-col>
      </v-row>
    </v-card>

    <div class="common-container my-10">
      <h2 v-if="boxDetail">
        {{ $tc("box-detail.table-title", boxDetail.length) }}
      </h2>
      <div v-if="boxDetail">
        <v-data-table
          :headers="headers"
          :items="boxDetail"
          :items-per-page="5"
          class="elevation-0"
          :hide-default-footer="boxDetail.length < 5"
        >
          <template v-slot:[`item.box_id`]="props">
            <router-link
              :to="{
                name: 'operations-report',
                params: { id: props.item.box_id },
              }"
            >
              {{ props.item.box_id }}
            </router-link>
          </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import i18n from "@/i18n";

export default {
  name: "box-detail",
  async mounted() {
    await this.fetchBoxDetail(this.$route.params.id);
  },
  data() {
    return {
      selectedLocation: null,
      cycleValue: null,
      status: [
        { name: i18n.t("cycle.status.positioned"), value: 1 },
        { name: i18n.t("cycle.status.in_transit"), value: 2 },
      ],
      selectedStatus: null,
      params: {},
      headers: [
        {
          text: i18n.t("cycle.table.id"),
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: i18n.t("cycle.table.barcode"), value: "barcode" },
        { text: i18n.t("cycle.table.area"), value: "area_description" },
        { text: i18n.t("cycle.table.location"), value: "location_description" },
        { text: i18n.t("cycle.table.date"), value: "date" },
      ],
    };
  },
  methods: {
    ...mapActions({
      fetchBoxDetail: "cycles/fetchBoxDetail",
    }),
  },
  computed: {
    ...mapGetters({
      boxDetail: "cycles/getBoxDetail",
    }),
  },
};
</script>

<style></style>
